<template>
  <div class="qAndA">
    <div class="qa-title-box">
      <h2 class="qa-title common-content">Q&A</h2>
    </div>
    <ul class="qa-list">
      <li class="qa-item" v-for="(item, key) in qaList" :key="key">
        <div
          class="qa-item-title-box"
          @click="showHandler(key)"
          ref="title"
          @mouseup="clickUD(false)"
          @mousedown="clickUD(true)"
        >
          <h5 class="qa-item-title font-bold common-content ">
            <div class="qa-item-icon"><i class="common-icon-minus"></i></div>
            <div
              class="qa-item-icon"
              :style="{ opacity: show[key] === 0 ? 1 : 0 }"
            >
              <i class="common-icon-plus"></i>
            </div>
            {{ item.question }}
          </h5>
        </div>
        <div class="qa-item-context" :style="{ height: show[key] + 'px' }">
          <div class="qa-item-info-box">
            <div class="qa-item-info common-content">
              <div
                class="qa-item-info-text "
                v-for="(child, index) in item.answer"
                :key="index"
              >
                {{ child }}
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <!--      :style="{ transform: `translate(${cursor.x}px ${cursor.y}px)`,left: `${cursor.x}px`, top: `${cursor.y}px`, }"-->
    <div
      class="pointer pointer-bg"
      :style="{ transform: `translate(${cursor.x}px, ${cursor.y}px)` }"
      v-show="cursorState"
    >
      <img src="~@/assets/images/variable/var_04_bg.png" alt="" />
    </div>
    <div
      class="pointer pointer-tip-box"
      :style="{
        transform: `translate(${cursor.x}px, ${cursor.y}px)`
      }"
    >
      <div
        class="pointer-tip"
        :style="{
          transform: `scale(${cursorClickState ? 0.9 : 1})`
        }"
        v-show="cursorState"
      ></div>
    </div>
    <div
      class="pointer pointer-tip-box"
      :style="{
        transform: `translate(${cursor.x}px, ${cursor.y}px)`
      }"
    >
      <div
        class="pointer-corrugated"
        :class="{ active: cursorClickState }"
        v-show="cursorState"
      ></div>
    </div>
    <div
      class="pointer pointer-cursor"
      :style="{ transform: `translate(${cursor.x}px, ${cursor.y}px)` }"
      v-show="cursorState"
    >
      <img src="~@/assets/images/variable/var_04_cursor.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'QAndA',
  data() {
    return {
      qaList: [
        {
          question: 'HOW ARE THE TRANSACTION FEES CALCULATED?',
          answer: [
            'Transactions made on f(x) Variable charges a small fee of 0.04% per transaction, depending on your trading volume.'
          ]
        },
        {
          question: 'DO I HAVE TO USE F(X)WALLET FOR TRADING ON F(X) VARIABLE?',
          answer: [
            'Yes. f(x)Wallet is currently the only way to bridge stablecoins $DAI, $USDT or $USDC from the Ethereum to Function X Network for trading on f(x)Variable.'
          ]
        }
      ],
      show: {
        0: 0,
        1: 0
      },
      showEl: {
        0: null,
        1: null
      },
      cursor: {
        x: 0,
        y: 0
      },
      cursorState: false,
      timer: false,
      cursorClickState: false
    }
  },
  methods: {
    showHandler(key) {
      if (this.show[key] !== 0) {
        this.$set(this.show, key, 0)
      } else {
        const h = this.showEl[key].scrollHeight
        this.$set(this.show, key, h)
      }
    },
    clickUD(state) {
      this.cursorClickState = state
    },
    showCursor() {
      this.cursorState = true
    },
    hideCursor() {
      this.cursorState = false
    },
    cursorChange(event) {
      if (this.timer) {
        return
      }
      this.timer = true
      window.requestAnimationFrame(() => {
        this.$set(this.cursor, 'x', event.clientX)
        this.$set(this.cursor, 'y', event.clientY)
        this.timer = false
      })
    }
  },
  mounted() {
    const els = document.querySelectorAll('.qa-item-info-box')
    this.showEl = {
      0: els[0],
      1: els[1]
    }
    const titleEl = this.$refs.title
    for (let i = 0; i < titleEl.length; i++) {
      titleEl[i].addEventListener('mouseenter', this.showCursor)
      titleEl[i].addEventListener('mousemove', this.cursorChange)
      titleEl[i].addEventListener('mouseleave', this.hideCursor)
    }
  },
  beforeDestroy() {
    const titleEl = this.$refs.title
    for (let i = 0; i < titleEl.length; i++) {
      titleEl[i].removeEventListener('mouseenter', this.showCursor)
      titleEl[i].removeEventListener('mousemove', this.cursorChange)
      titleEl[i].removeEventListener('mouseleave', this.hideCursor)
    }
  }
}
</script>

<style scoped lang="scss">
.qAndA {
  width: 100%;
  padding-top: 148px;
  padding-bottom: 128px;
  background-color: var(--bg-color);
  color: #fff;
  .qa-title-box {
    width: 100%;
  }
  .qa-title {
  }
  .qa-list {
    margin-top: 30px;
  }
  .qa-item {
  }
  .qa-item-title-box {
    padding-top: 24px;
    padding-bottom: 24px;
    transition: background-color 0.3s;
    //cursor: url('~@/assets/images/variable/var_04_cursor.png'), pointer;
    cursor: none;
    &:hover {
      background-color: rgba(255, 255, 255, 0.06);
    }
  }
  .qa-item-title {
    text-transform: uppercase;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .qa-item-icon {
    width: 24px;
    margin-right: 16px;
    transition: opacity 0.3s;
    &:nth-of-type(2) {
      position: absolute;
    }
  }
  .qa-item-context {
    position: relative;
    overflow: hidden;
    transition: height 0.3s;
  }
  .qa-item-info-box {
    top: 0;
    left: 0;
    width: 100%;
    position: absolute;
  }
  .qa-item-info {
    padding-top: 8px;
    padding-bottom: 48px;
    padding-left: 60px;
  }
  .qa-item-info-text {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    max-width: 900px;
    & + .qa-item-info-text {
      margin-top: 24px;
    }
  }
  .pointer {
    position: fixed;
    pointer-events: none;
  }
  .pointer-cursor {
    top: 0;
    left: 0;
    width: 35px;
  }
  .pointer-tip-box {
    top: -8px;
    left: -8px;
  }
  .pointer-tip {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: #b0fbff;
    transition: transform 0.08s linear;
  }
  .pointer-corrugated {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: #b0fbff;
    opacity: 0;
    transform: scale(5);
    transition-duration: 0.28s;
    transition-property: transform, opacity;
    &.active {
      transition: none;
      opacity: 0.5;
      transform: scale(0.9);
    }
  }
  .pointer-bg {
    width: 70px;
    top: -22px;
    left: -22px;
    transition: transform 0.08s linear;
  }
}
</style>
