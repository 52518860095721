<template>
  <div class="decentralized">
    <div class="dd-content common-content">
      <div class="dd-item dd">
        <div class="dd-context">
          <h3 class="dd-title">FULLY DECENTRALIZED</h3>
          <div class="dd-note common-text white">
            Everything is processed on chain including modifying and canceling
            orders, offering a non-custodial, permissionless and most
            transparent experience for trading perpetual future contracts 24/7
            hours.
          </div>
        </div>
        <div class="dd-img">
          <div class="dd-img-panel">
            <img src="~@/assets/images/variable/var_02_dec.png" alt="" />
          </div>
          <div class="dd-img-control">
            <img src="~@/assets/images/variable/var_02_control.png" alt="" />
          </div>
          <div
            class="dd-img-move"
            :style="{ transform: `rotate(${ddRotate}deg)` }"
          >
            <img src="~@/assets/images/variable/var_02_dec_move.png" alt="" />
          </div>
          <div class="dd-img-shadow">
            <img
              src="~@/assets/images/variable/var_02_xrobot1_backlight.svg"
              alt=""
            />
          </div>
          <div class="dd-img-robot">
            <img src="~@/assets/images/variable/var_02_xrobot1.png" alt="" />
          </div>
        </div>
      </div>
      <div class="dd-item gas">
        <div class="dd-img">
          <div class="dd-img-panel">
            <img src="~@/assets/images/variable/var_02_gas.png" alt="" />
          </div>
          <div class="dd-img-control">
            <img src="~@/assets/images/variable/var_02_control.png" alt="" />
          </div>
          <div
            class="dd-img-move"
            :style="{ transform: `rotate(${gasRotate}deg)` }"
          >
            <img src="~@/assets/images/variable/var_02_gas_move.png" alt="" />
          </div>
        </div>
        <div class="dd-context">
          <h3 class="dd-title">Low gas fee</h3>
          <div class="dd-note common-text white">
            Make each transaction at a fixed gas fee as low as 0.0003 FX
          </div>
        </div>
      </div>
      <div class="dd-item slippage">
        <div class="dd-context">
          <h3 class="dd-title">Zero slippage</h3>
          <div class="dd-note common-text white">
            Leveraging with our framework, traders are guaranteed to have some
            best price execution around.
          </div>
        </div>
        <div class="dd-img">
          <div class="dd-img-panel">
            <img src="~@/assets/images/variable/var_02_slippage.png" alt="" />
          </div>
          <div class="dd-img-control">
            <img src="~@/assets/images/variable/var_02_control.png" alt="" />
          </div>
          <div
            class="dd-img-price dd-img-price-line dd-img-coin"
            :style="{ opacity: 1 - slippageOp }"
          >
            <img
              src="~@/assets/images/variable/var_02_slippage_ic_price1.png"
              alt=""
            />
          </div>
          <div
            class="dd-img-price dd-img-price-fill dd-img-coin"
            :style="{ opacity: slippageOp }"
          >
            <img
              src="~@/assets/images/variable/var_02_slippage_ic_price2.png"
              alt=""
            />
          </div>
          <div
            class="dd-img-time dd-img-time-line dd-img-coin"
            :style="{ opacity: slippageOp }"
          >
            <img
              src="~@/assets/images/variable/var_02_slippage_ic_time1.png"
              alt=""
            />
          </div>
          <div
            class="dd-img-time dd-img-time-fill dd-img-coin"
            :style="{ opacity: 1 - slippageOp }"
          >
            <img
              src="~@/assets/images/variable/var_02_slippage_ic_time2.png"
              alt=""
            />
          </div>
          <div class="dd-img-shadow">
            <img
              src="~@/assets/images/variable/var_02_xrobot2_backlight.svg"
              alt=""
            />
          </div>
          <div class="dd-img-robot">
            <img src="~@/assets/images/variable/var_02_xrobot2.png" alt="" />
          </div>
        </div>
      </div>
      <div class="dd-item safe">
        <div class="dd-img">
          <div class="dd-img-panel">
            <img src="~@/assets/images/variable/var_02_safe.png" alt="" />
          </div>
          <div class="dd-img-control">
            <img src="~@/assets/images/variable/var_02_control.png" alt="" />
          </div>
          <div class="dd-img-move" :style="{ opacity: safeOp }">
            <img src="~@/assets/images/variable/var_02_safe_move.png" alt="" />
          </div>
        </div>
        <div class="dd-context">
          <h3 class="dd-title">Safe and efficient</h3>
          <div class="dd-note common-text white">
            Provide a throughput at 1,000 transactions per second while traders
            are unlikely to experience the downtime seen on CEXs during periods
            of high volatility.
          </div>
        </div>
      </div>
      <div class="dd-btn">
        <CButton type="comicWhite" :height="42" @click="goOut">
          <div class="common-btn-content">
            View More Features
            <div class="common-btn-icon">
              <i class="common-icon-open"></i>
            </div>
          </div>
        </CButton>
      </div>
    </div>
  </div>
</template>

<script>
import { CustomEase, gsap } from '@/tools/gsap'
import CButton from '@/components/common/CButton'
import { EventBus } from '@/tools/bus'

export default {
  name: 'Decentralized',
  components: { CButton },
  data() {
    return {
      ddRotate: 0,
      gasRotate: 0,
      slippageOp: 1,
      safeOp: 1
    }
  },
  methods: {
    goOut() {
      EventBus.$emit('pop-dvantage')
    }
  },
  mounted() {
    const ddTl = gsap.timeline({
      repeat: -1,
      repeatDelay: 0.3
    })
    const ddRotateAnime1 = gsap.to(this.$data, {
      ddRotate: 360,
      duration: 1,
      ease: CustomEase.create('custom', 'M0,0 C0.33,0 0.67,1 1,1 ')
    })
    const ddRotateAnime2 = gsap.to(this.$data, {
      ddRotate: 365,
      duration: 0.08,
      ease: CustomEase.create('custom', 'M0,0 C0.33,0 0.67,1 1,1 ')
    })
    const ddRotateAnime3 = gsap.to(this.$data, {
      ddRotate: 360,
      duration: 0.08,
      ease: CustomEase.create('custom', 'M0,0 C0.33,0 0.67,0.91 1,1 ')
    })
    const ddRotateAnime4 = gsap.to(this.$data, {
      ddRotate: 0,
      duration: 1.8,
      ease: CustomEase.create('custom', 'M0,0 C0.33,0 0.67,1 1,1 ')
    })
    ddTl.add(ddRotateAnime1)
    ddTl.add(ddRotateAnime2)
    ddTl.add(ddRotateAnime3)
    ddTl.add(ddRotateAnime4, '+=0.44')

    const gasTl = gsap.timeline({
      repeat: -1,
      repeatDelay: 0.3
    })
    const gasRotateAnime1 = gsap.to(this.$data, {
      gasRotate: 120,
      duration: 2,
      ease: 'none'
    })
    const gasRotateAnime2 = gsap.to(this.$data, {
      gasRotate: 0,
      duration: 1,
      ease: 'none'
    })
    gasTl.add(gasRotateAnime1)
    gasTl.add(gasRotateAnime2, '+=0.4')

    const slippageTl = gsap.timeline({
      repeat: -1,
      repeatDelay: 1.8
    })
    const slippageOpAnime1 = gsap.to(this.$data, {
      slippageOp: 0,
      duration: 0.4,
      ease: 'none'
    })
    const slippageOpAnime2 = gsap.to(this.$data, {
      slippageOp: 1,
      duration: 0.4,
      ease: 'none'
    })
    slippageTl.add(slippageOpAnime1)
    slippageTl.add(slippageOpAnime2, '+=1.4')

    const safeTl = gsap.timeline({
      repeat: -1,
      repeatDelay: 1.2
    })
    const safeOpTl = gsap.timeline({
      repeat: 1
    })
    const safeOpAnime1 = gsap.to(this.$data, {
      safeOp: 0,
      duration: 0.2,
      ease: 'none'
    })
    const safeOpAnime2 = gsap.to(this.$data, {
      safeOp: 1,
      duration: 0.2,
      ease: 'none'
    })
    safeOpTl.add(safeOpAnime1)
    safeOpTl.add(safeOpAnime2)
    safeTl.add(safeOpTl)
  }
}
</script>

<style scoped lang="scss">
.decentralized {
  width: 100%;
  position: relative;
  padding-bottom: 128px;
  background-image: linear-gradient(
    to bottom,
    #191825 0%,
    #1b1a28 164px,
    #1b1a28 100%
  );
  .dd-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .dd-item {
    &.dd {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .dd-img {
        margin-left: 16px;
      }
      .dd-img-move {
        width: 57px;
        top: 20px;
        left: 45px;
      }
      .dd-img-shadow {
        width: 198px;
        top: 37px;
        left: 106px;
      }
      .dd-img-robot {
        width: 213px;
        top: 55px;
        left: 61px;
      }
    }
    &.gas {
      margin-left: auto;
      margin-top: -32px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      .dd-img {
        .dd-img-move {
          width: 20px;
          top: 58px;
          left: 58px;
          transform-origin: 90% center;
        }
      }
      .dd-context {
        text-align: right;
        margin-top: 32px;
      }
    }
    &.slippage {
      margin-top: 64px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-left: 160px;
      .dd-img {
        margin-left: 200px;
        margin-top: 20px;
      }
      .dd-img-robot {
        width: 219px;
        top: -66px;
        left: 172px;
      }
      .dd-img-shadow {
        width: 210px;
        top: -81px;
        left: 211px;
      }
      .dd-img-price {
        top: 32px;
        left: 68px;
      }
      .dd-img-price-line {
      }
      .dd-img-price-fill {
        z-index: 4;
      }
      .dd-img-time {
        top: 32px;
        left: 33px;
      }
      .dd-img-time-line {
      }
      .dd-img-time-fill {
      }
    }
    &.safe {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 168px;
      margin-right: 110px;
      margin-left: auto;
      .dd-img {
      }
      .dd-img-move {
        width: 29px;
        top: 27px;
        left: 56px;
      }
      .dd-context {
        text-align: right;
        margin-top: 14px;
        margin-left: 24px;
      }
    }
  }
  .dd-context {
    max-width: 390px;
  }
  .dd-title {
    color: #fff;
    text-transform: uppercase;
  }
  .dd-note {
    margin-top: 8px;
  }
  .dd-img {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    // 利用translateZ(0)给动画层单独生成一个渲染层 防止快速滚动时出现白杠
    transform: translateZ(0);
  }
  .dd-img-panel {
    width: 198px;
  }
  .dd-img-control {
    width: 210px;
    margin-top: -7px;
  }
  .dd-img-move {
    position: absolute;
  }
  .dd-img-shadow {
    position: absolute;
  }
  .dd-img-robot {
    position: absolute;
  }
  .dd-img-coin {
    position: absolute;
    width: 51px;
  }
  .dd-btn {
    margin-left: auto;
    margin-right: auto;
    margin-top: 96px;
  }
}
</style>
