<template>
  <div
    class="stock-box"
    ref="stockBox"
    :style="{ height: stockBoxH + stockBoxPause + 'px' }"
  >
    <div class="sk-pin" ref="stock">
      <div class="stock">
        <div class="sk-cd common-content">
          <CountdownDay :endTime="COUNTDOWN" />
        </div>
        <div class="sk-content common-content" :style="{ opacity: contentOp }">
          <h3 class="sk-title-1">DERIVATIVES TRADING ON FUNCTION X</h3>
          <h1 class="sk-title-2">
            F(X) <span class="common-angle-variable">VARIABLE</span> EXPOSURE TO
            REAL WORLD ASSETS
          </h1>
          <h2 class="sk-title-3">TOKENIZED STOCKS PERPETUAL CONTRACTS</h2>
          <div class="sk-note common-text-big">
            Trade 24/7 with zero slippage and low gas fee. Get all the benefits
            of a fully on-chain permissionless futures trading experience.
          </div>
        </div>
        <div class="sk-panel-box">
          <div
            class="sk-panel"
            :style="{ transform: `translateX(${panelX}px)` }"
          >
            <div class="sk-panel-bg" :style="{ opacity: panelBgOp }">
              <img
                src="~@/assets/images/variable/var_01_backlight.svg"
                alt=""
              />
            </div>
            <div class="sk-panel-pc">
              <div class="sk-panel-pc-plate">
                <img src="~@/assets/images/variable/var_01_web.png" alt="" />
              </div>
              <div class="sk-panel-pc-content">
                <img
                  src="~@/assets/images/variable/f(x)DexHomepage@2x.jpg"
                  alt=""
                />
              </div>
            </div>
            <div class="sk-panel-mobile">
              <div class="sk-panel-mobile-plate">
                <img src="~@/assets/images/variable/var_01_mobile.png" alt="" />
              </div>
              <div class="sk-panel-mobile-content">
                <img
                  src="~@/assets/images/variable/fxWalletConnect@2x.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="fc-num-btn-box common-content">
          <div
            class="fc-num-btn"
            :style="{ transform: `translateX(${btnX}px)` }"
          >
            <CButton
              type="colorful"
              :height="42"
              :minWidth="btnW + 'px'"
              @click="goOut"
            >
              <div class="common-btn-content">
                Start Trading
                <div class="common-btn-icon">
                  <i class="common-icon-jump"></i>
                </div>
              </div>
            </CButton>
          </div>
        </div>
      </div>
      <FxDex />
    </div>
  </div>
</template>

<script>
import CButton from '@/components/common/CButton'
import { gsap, ScrollTrigger } from '@/tools/gsap'
import FxDex from '@/components/variable/FxDex'
import { debounce } from 'lodash/function'
import CountdownDay from '@/components/variable/CountdownDay'
import { COUNTDOWN } from '@/tools/variable'
let resize = null

export default {
  name: 'Stock',
  data() {
    return {
      COUNTDOWN: COUNTDOWN,
      stockBoxH: 4000,
      stockBoxPause: 500,
      stockH: 0,
      panelX: 752,
      panelBgOp: 0.5,
      contentOp: 1,
      btnW: 156,
      btnX: 0,
      timeline: null
    }
  },
  components: { CountdownDay, FxDex, CButton },
  methods: {
    goOut() {
      window.open('https://testnet-dex.functionx.io/')
    },
    resizeFc() {
      let panelX = 752
      if (window.innerHeight < 700) {
        // 724 - ((1280 - (1003 * 0.67)) / 2) / 0.67
        panelX = 626
      }
      if (this.timeline !== null) {
        this.timeline.seek(0).kill()
      }
      this.timeline = gsap.timeline({
        scrollTrigger: {
          trigger: this.$refs.stockBox,
          start: 'top top+=60',
          end: () => `top+=${this.stockBoxH - this.stockH} top+=60`,
          scrub: true,
          markers: process.env.NODE_ENV === 'development'
        }
      })
      this.timeline.addLabel('start')
      const panelAnime = gsap.fromTo(
        this.$data,
        {
          panelX: panelX
        },
        {
          panelX: 0,
          ease: 'none',
          duration: 4
        }
      )
      const panelBgAnime = gsap.to(this.$data, {
        panelBgOp: 0.1,
        ease: 'none',
        repeat: 3,
        yoyo: true,
        duration: 1
      })
      const contentAnime = gsap.to(this.$data, {
        contentOp: 0,
        ease: 'none',
        duration: 4
      })
      const btnAnime = gsap.to(this.$data, {
        btnX: (1200 - this.btnW) / 2,
        ease: 'none',
        duration: 4
      })
      this.timeline.add(panelAnime, 'start')
      this.timeline.add(panelBgAnime, 'start')
      this.timeline.add(contentAnime, 'start')
      this.timeline.add(btnAnime, 'start')
      ScrollTrigger.refresh()
    }
  },
  mounted() {
    this.stockH = this.$refs.stock.offsetHeight
    document.fonts.ready.then(() => {
      // 字体加载完成后的逻辑
      this.stockH = this.$refs.stock.offsetHeight
      ScrollTrigger.refresh()
    })
    resize = debounce(this.resizeFc, 300)
    window.addEventListener('resize', resize)
    resize()
  },
  beforeDestroy() {
    window.removeEventListener('resize', resize)
  }
}
</script>

<style scoped lang="scss">
.stock-box {
  width: 100%;
  background-color: #0d0c15;
}
.sk-pin {
  position: sticky;
  top: $headHeight;
  overflow: hidden;
}
.stock {
  background-image: linear-gradient(0deg, #0d0c15 0%, #1b1a28 100%);
  width: 100%;
  padding-top: 24px;
  padding-bottom: 120px;
  //position: relative;
  .sk-content {
    color: #fff;
    padding-top: 50px;
  }
  .sk-title-1 {
    text-transform: uppercase;
    max-width: 840px;
  }
  .sk-title-2 {
    max-width: 840px;
    margin-top: 100px;
  }
  .sk-title-3 {
    max-width: 658px;
    margin-top: 64px;
  }
  .sk-note {
    max-width: 720px;
    margin-top: 8px;
  }
  .sk-panel-box {
    position: absolute;
    top: 134px;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
  }
  .sk-panel {
    position: relative;
    //transform: translateX(771px);
  }
  .sk-panel-pc {
    padding-left: 73px;
    padding-bottom: 23px;
  }
  .sk-panel-pc-plate {
    width: 930px;
    position: relative;
  }
  .sk-panel-pc-content {
    width: 864px;
    position: absolute;
    top: 89px;
    right: 33px;
  }
  .sk-panel-mobile {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .sk-panel-mobile-plate {
    width: 211px;
  }
  .sk-panel-mobile-content {
    position: absolute;
    width: 169px;
    top: 20px;
    left: 21px;
  }
  .sk-panel-bg {
    position: absolute;
    width: 1265px;
    top: -68px;
    left: -139px;
    opacity: 0.5;
  }
  .fc-num-btn-box {
    margin-top: 48px;
  }
  .fc-num-btn {
    //transform: translateX(calc(100vw - 1200px));
  }
}
@media screen and (max-height: 700px) {
  .stock {
    padding-top: 14px;
    padding-bottom: 50px;
    .sk-content {
      padding-top: 20px;
    }
    .sk-title-1 {
      font-size: 24px;
      line-height: 28px;
      max-width: 600px;
    }
    .sk-title-2 {
      max-width: 520px;
      margin-top: 0px;
      font-size: 86px;
      line-height: 72px;
    }
    .sk-title-3 {
      max-width: 300px;
      margin-top: 36px;
      font-size: 40px;
      line-height: 34px;
    }
    .sk-note {
      max-width: 600px;
      margin-top: 0;
      font-size: 16px;
      line-height: 24px;
    }
    .sk-panel-box {
      top: 84px;
      transform: scale(0.64) translateX(-50%);
      transform-origin: top left;
    }
    .sk-panel-pc {
      padding-bottom: 0;
    }
    .fc-num-btn-box {
      margin-top: 25px;
    }
  }
}
</style>
