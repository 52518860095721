<template>
  <div class="dvantage">
    <h2 class="dt-title">TRADE PERPETUAL FUTURES LIKE THE BEST</h2>
    <!--    <div class="dt-note common-text white"></div>-->
    <ul class="dt-list">
      <li class="dt-item" v-for="item in list" :key="item.title">
        <div class="dt-item-img">
          <img :src="require('@/assets/images/variable/' + item.img)" alt="" />
        </div>
        <div class="dt-item-context">
          <h3 class="dt-item-title">{{ item.title }}</h3>
          <div class="dt-item-note common-text white">{{ item.note }}</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Dvantage',
  data() {
    return {
      list: [
        {
          img: 'contract@2x.png',
          title: 'Perpetuals',
          note:
            'Tokenized perpetuals enables crypto traders to participate in market movements of real-world popular stocks using stablecoin collateral, and hold positions and make profits without an expiry date unless they get liquidated.'
        },
        {
          img: 'rate@2x.png',
          title: 'Price Oracle',
          note:
            'Asset price on f(x) Variable is an aggregate price calculated using multiple on-chain price oracles, which is tamper-proof, protecting users from events such as flash crashes on any single stock exchange.'
        },
        {
          img: 'guarantee@2x.png',
          title: 'Insurance Fund',
          note:
            'At the time of launching f(x) Variable, Function X seeds an insurance fund that will be the initial backstop for any underwater accounts. Insurance Fund is used to protect bankrupt traders from adverse losses and ensure that the profits of winning traders are paid out in full.'
        },
        {
          img: 'orderbook@2x.png',
          title: 'On-Chain Orderbook',
          note:
            'f(x)Variable uses on-chain orderbook. As the orderbook is hosted directly on the distributed ledger, traders could buy/sell via a single transaction directly to their decentralized f(x)Wallet. All orders are submitted to the distributed ledger network and are confirmed by the network. Fast, efficient and easy to use.'
        },
        {
          img: 'match@2x.png',
          title: 'On-Chain Match-making',
          note:
            'The matching happens within the blockchain nodes -- the match is executed only once in each block -- and all of the transactions are recorded on-chain.  As for the logic, f(x)Variable applies call auction mechanism, in which orders collected during a call auction are all executed at the price that forms the best overall match.'
        },
        {
          img: 'settlement@2x.png',
          title: 'On-Chain Settlement',
          note:
            'All decentralized exchanges feature on-chain settlement, so does f(x)Variable. On f(x)Variable, however,  it is the Validator Nodes that enforces all settlement processes relevant to traders, which means your tokens will be sent to you directly after settlement. No need to interact with the smart contract, but the chain itself. Same secure and transparent, but faster and more convenient.'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.dvantage {
  width: 100%;
  color: #fff;
  padding-bottom: 151px;
  padding-top: 12px;
  .dt-title {
    text-align: center;
  }
  .dt-note {
    width: 804px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 8px;
  }
  .dt-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 104px;
    padding-left: 49px;
    padding-right: 20px;
  }
  .dt-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &:nth-of-type(2n) {
      align-self: flex-end;
    }
    & + .dt-item {
      margin-top: 102px;
    }
  }
  .dt-item-img {
    width: 128px;
    flex-shrink: 0;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: -29px;
      left: -29px;
      right: -29px;
      bottom: -29px;
      z-index: -1;
      background-image: url('~@/assets/images/variable/var_icon_backlight.svg');
    }
  }
  .dt-item-context {
    width: 600px;
    margin-left: 48px;
  }
  .dt-item-title {
    margin-top: 12px;
  }
  .dt-item-note {
    margin-top: 8px;
  }
}
</style>
