<template>
  <div class="countdownDay" @click="go">
    <div class="cd-l">
      <div class="cd-l-time font-bold" v-if="time.value > 0">
        {{
          `${format(time.days)} : ${format(time.hours)} : ${format(
            time.minutes
          )} : ${format(time.seconds)}`
        }}
      </div>
      <div class="cd-l-address" v-else>
        <div class="cd-l-title font-bold">
          <ICountUp
            :endVal="addressNum"
            :options="addressOptions"
            v-if="addressNum !== 0"
          />
          {{ addressNum === 0 ? '~' : '' }}
        </div>
        <div class="cd-l-text font-condensed">Participating Addresses</div>
      </div>
    </div>
    <div class="cd-r">
      <div class="cd-r-context ">
        <div class="cd-r-title font-bold">
          f(x)Variable Public Alpha Program
        </div>
        <div class="cd-r-text font-condensed" v-if="time.value > 0">
          Registration & Referral
        </div>
        <div class="cd-r-text font-condensed" v-else>View the leaderboard</div>
      </div>
      <div class="cd-r-icon"><i class="common-icon-jump"></i></div>
    </div>
  </div>
</template>

<script>
import countdown from 'countdown'
import BN from '@/tools/BN'
import http from '@/http/api'
import ICountUp from 'vue-countup-v2'

export default {
  name: 'CountdownDay',
  data() {
    return {
      // targetTime: '1625580000000', // 2021-06-21 08:00:00 UAT +8
      time: {
        days: '~',
        hours: '~',
        minutes: '~',
        seconds: '~'
      },
      timer: null,
      addressNum: 0,
      addressOptions: {
        duration: 1
        // decimalPlaces: 2
      }
    }
  },
  props: {
    endTime: String
  },
  components: {
    ICountUp
  },
  computed: {
    addressNumFormat() {
      if (this.addressNum === '~') {
        return this.addressNum
      }
      return new BN(this.addressNum).toFormat(0)
    }
  },
  methods: {
    go() {
      window.open('https://testnet-dex.functionx.io/variable-invite')
    },
    format(num) {
      const StringNum = String(num)
      if (StringNum.length < 2) {
        return '0' + StringNum
      }
      return StringNum
    },
    runTime() {
      this.time = countdown(
        null,
        this.endTime,
        countdown.DAYS | countdown.HOURS | countdown.MINUTES | countdown.SECONDS
      )
      if (this.time.value > 0) {
        this.timer = setTimeout(() => {
          this.runTime()
        }, 1000)
      } else {
        this.time = {
          days: '0',
          hours: '00',
          minutes: '00',
          seconds: '00'
        }
        http.addressNum().then(data => (this.addressNum = data.totalNum))
      }
    }
  },
  mounted() {
    this.runTime()
  }
}
</script>

<style scoped lang="scss">
.countdownDay {
  width: 100%;
  height: 60px;
  border-radius: 16px;
  background-image: url('~@/assets/images/variable/img_actbar@2x.png');
  //  linear-gradient(180deg, rgba(255, 255, 255, 0.24) 0%, #ffffff 100%);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: left center;
  overflow: hidden;
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transform: translateZ(0);
  .cd-l {
    color: var(--main);
  }
  .cd-l-time {
    font-size: 36px;
    line-height: 34px;
    font-weight: 500;
  }
  .cd-l-address {
  }
  .cd-l-title {
    font-size: 26px;
    line-height: 1em;
    font-weight: 500;
  }
  .cd-l-text {
    font-size: 14px;
    line-height: 1em;
    font-weight: 400;
    margin-top: 2px;
  }
  .cd-r {
    color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .cd-r-context {
    text-align: right;
    margin-right: 8px;
  }
  .cd-r-title {
    font-size: 18px;
    line-height: 1em;
    font-weight: 500;
  }
  .cd-r-text {
    font-size: 14px;
    line-height: 1em;
    font-weight: 400;
    margin-top: 2px;
  }
  .cd-r-icon {
    width: 16px;
    flex-shrink: 0;
    transition: transform 0.3s;
  }
  &:hover {
    .cd-r-icon {
      transform: translateX(4px);
    }
  }
}

@media screen and (max-height: 700px) {
  .countdownDay {
    background-image: url('~@/assets/images/variable/img_actbar_mini@2x.png');
    height: 50px;
    .cd-l-address {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-start;
      align-items: center;
    }
    .cd-l-text {
      margin-right: 8px;
    }
    .cd-r-context {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
    .cd-r-text {
      margin-left: 4px;
    }
  }
}
</style>
