<template>
  <div class="cpopup" :class="{ show: showState }">
    <div class="cpp-bg" @click="hide"></div>
    <div class="cpp-content">
      <div class="cpp-close" @click="hide">
        <i class="common-icon-close"></i>
      </div>
      <div class="cpp-slot" ref="slot">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CPopup',
  data() {
    return {
      showState: false,
      key: ''
    }
  },
  methods: {
    show() {
      document.body.classList.add('noScroll')
      window.history.replaceState(
        { pop: 'origin' },
        document.title,
        location.href
      )
      this.key = Math.floor(Math.random() * 10000)
      window.history.pushState({ pop: this.key }, document.title, location.href)
      this.showState = true
    },
    hide() {
      this.$router.go(-1)
    },
    escKeyUpEvent(e) {
      if (e.code !== 'Escape') {
        return
      }
      if (
        history.state &&
        history.state.pop &&
        history.state.pop === this.key
      ) {
        this.$router.go(-1)
      }
    },
    popStateEvent(e) {
      if (e.state && e.state.pop) {
        if (e.state.pop === 'origin') {
          document.body.classList.remove('noScroll')
          this.showState = false
        }
        if (e.state.pop === this.key) {
          this.show()
        }
      }
    }
  },
  mounted() {
    window.addEventListener('popstate', this.popStateEvent)
    window.addEventListener('keyup', this.escKeyUpEvent)
  },
  beforeDestroy() {
    document.body.classList.remove('noScroll')
    window.removeEventListener('popstate', this.popStateEvent)
  }
}
</script>

<style>
.noScroll {
  overflow: hidden;
  position: relative;
}
</style>

<style scoped lang="scss">
.cpopup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 40px;
  z-index: 100;
  min-width: var(--content-width-padding);
  opacity: 0;
  visibility: hidden;
  transition-duration: 0.3s;
  transition-property: visibility, opacity;
  &.show {
    opacity: 1;
    visibility: visible;
    .cpp-bg {
      opacity: 1;
    }
    .cpp-content {
      opacity: 1;
      transform: scale(1) translateZ(0);
    }
  }
}
.cpp-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  transition: opacity 0.3s;
  opacity: 0;
}
.cpp-content {
  position: relative;
  transform-origin: bottom center;
  width: var(--content-width);
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  padding-top: 72px;
  box-sizing: border-box;
  background-image: linear-gradient(0deg, #0d0c15 0%, #1b1a28 100%);
  border-radius: 36px 36px 0 0;
  transition-duration: 0.3s;
  transition-property: transform, opacity;
  opacity: 0;
  transform: scale(0.96) translateZ(0);
  padding-right: 4px;
}
.cpp-close {
  position: absolute;
  top: 24px;
  right: 24px;
  padding: 12px;
  width: 48px;
  height: 48px;
  cursor: pointer;
}
.cpp-slot {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: 10px;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0);
    border-radius: 3px;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}
</style>
