<template>
  <div class="transaction">
    <div class="tc-content common-content">
      <div class="tc-context">
        <h2 class="tc-title">HOW TO PARTICIPATE</h2>
        <div class="tc-note common-text white">
          To start trading the tokenized stock perpetual contracts provided by
          f(x) Variable, you’ll need the following two types of cryptos:
        </div>
        <ul class="tc-info">
          <li class="tc-info-item" v-for="item in infoList" :key="item.img">
            <div class="tc-info-icon">
              <i :class="'common-icon-' + item.img"></i>
            </div>
            <div class="tc-info-text common-text white">{{ item.text }}</div>
          </li>
        </ul>
        <div class="tc-note-2 common-text white">
          START TRADING IN JUST 3 STEPS:
        </div>
        <ul class="tc-list">
          <li
            class="tc-item-bg"
            :style="{ transform: `translateY(${activeNum * (124 + 16)}px)` }"
          ></li>
          <li
            class="tc-item"
            :class="{ active: key === activeNum }"
            v-for="(item, key) in stepList"
            :key="key"
            @click="activeNum = key"
          >
            <h3 class="tc-item-title">STEP. {{ key + 1 }}</h3>
            <div class="tc-item-note common-text">{{ item }}</div>
          </li>
        </ul>
      </div>
      <div class="tc-panel">
        <div class="tc-panel-light"></div>
        <div class="tc-panel-base">
          <img src="~@/assets/images/variable/var_03_base.png" alt="" />
        </div>
        <div class="tc-panel-page">
          <div
            class="tc-panel-page-panel"
            :style="{ transform: `translateY(-${activeNum * 420}px)` }"
          >
            <div class="tc-panel-page-item">
              <img src="~@/assets/images/variable/var_03_step1@2x.jpg" alt="" />
            </div>
            <div class="tc-panel-page-item">
              <img src="~@/assets/images/variable/var_03_step2@2x.jpg" alt="" />
            </div>
            <div class="tc-panel-page-item">
              <img src="~@/assets/images/variable/var_03_step3@2x.jpg" alt="" />
            </div>
          </div>
        </div>
        <div class="tc-panel-plate">
          <img src="~@/assets/images/variable/var_03_frame.png" alt="" />
        </div>

        <div
          class="tc-panel-ring-1"
          :style="{ transform: `rotate(${ring1Ro}deg)` }"
        >
          <img src="~@/assets/images/variable/var_03_circle1.svg" alt="" />
        </div>
        <div
          class="tc-panel-ring-2"
          :style="{ transform: `rotate(${ring2Ro}deg)` }"
        >
          <img src="~@/assets/images/variable/var_03_circle2.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap, CustomEase } from '@/tools/gsap'

export default {
  name: 'Transaction',
  data() {
    return {
      stepList: [
        'You’ll need a f(x)Wallet with FX for gas fee & transaction fees',
        'Cross-chain transfer stablecoins using your f(x)Wallet via f(x)Bridge to FX Variable Chain',
        'Scan the QR Code on the web page to connect with your f(x)Wallet and start trading '
      ],
      infoList: [
        {
          img: 'info-fx',
          text:
            'FX, the native coin of Function X Network, to pay for gas and transaction fees as f(x) Variable is on the Function X Network.'
        },
        {
          img: 'info-dt',
          text:
            'Bridged stablecoins $DAI, $USDT or $USDC from the Ethereum to the Function X Network.'
        }
      ],
      activeNum: 0,
      ring1Ro: 6,
      ring2Ro: -6
    }
  },
  mounted() {
    const tl = gsap.timeline({
      repeat: -1
    })
    const anime1 = gsap.to(this.$data, {
      ring1Ro: -6,
      ring2Ro: 6,
      ease: CustomEase.create('custom', 'M0,0 C0.33,0 0.67,1 1,1 '),
      duration: 2.52
    })
    const anime2 = gsap.to(this.$data, {
      ring1Ro: 6,
      ring2Ro: -6,
      duration: 2.48,
      ease: CustomEase.create('custom', 'M0,0 C0.33,0 0.67,1 1,1 '),
      immediateRender: false
    })
    tl.add(anime1)
    tl.add(anime2)
  }
}
</script>

<style scoped lang="scss">
.transaction {
  width: 100%;
  padding-top: 60px;
  background-color: var(--bg-color);
  .tc-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .tc-context {
    color: #fff;
  }
  .tc-title {
    width: 550px;
  }
  .tc-note {
    width: 580px;
    margin-top: 8px;
  }
  .tc-info {
    margin-top: 8px;
    width: 508px;
  }
  .tc-info-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 24px 8px 16px;
  }
  .tc-info-icon {
    flex-shrink: 0;
    width: 32px;
  }
  .tc-info-text {
    margin-left: 16px;
    font-size: 14px;
  }
  .tc-note-2 {
    margin-top: 72px;
  }
  .tc-list {
    margin-top: 16px;
    position: relative;
  }
  .tc-item-bg {
    height: 124px;
    width: 400px;
    border-radius: 4px;
    border: 2px solid #fff;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.3s;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url('~@/assets/images/variable/bg_grid_white.png');
      background-size: 400px 400px;
      transform: translate(4px, 4px);
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--bg-color);
    }
  }
  .tc-item {
    height: 124px;
    width: 400px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    opacity: 0.2;
    transition: all 0.3s;
    transform: translateZ(0);
    cursor: pointer;
    position: relative;
    & + .tc-item {
      margin-top: 16px;
    }
    &.active {
      opacity: 1;
    }
    &:hover:not(.active) {
      opacity: 0.5;
    }
  }
  .tc-item-title {
    position: relative;
    z-index: 1;
  }
  .tc-item-note {
    position: relative;
    z-index: 1;
    color: #fff;
  }
  .tc-panel {
    position: relative;
    margin-top: 49px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .tc-panel-light {
    width: 375px;
    height: 620px;
    background-image: linear-gradient(
      180deg,
      rgba(165, 230, 255, 0) 0%,
      rgba(165, 230, 255, 0.14) 100%
    );
  }
  .tc-panel-base {
    width: 466px;
    margin-top: -36px;
  }
  .tc-panel-plate {
    position: absolute;
    width: 338px;
    top: 89px;
    left: 64px;
  }
  .tc-panel-page {
    position: absolute;
    width: 306px;
    height: 420px;
    top: 105px;
    left: 80px;
    overflow: hidden;
  }
  .tc-panel-page-panel {
    transition: transform 0.3s;
  }
  .tc-panel-ring-1 {
    position: absolute;
    width: 424px;
    left: 16px;
    top: 542px;
  }
  .tc-panel-ring-2 {
    position: absolute;
    width: 456px;
    left: 5px;
    top: 552px;
  }
}
</style>
