var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qAndA"},[_vm._m(0),_c('ul',{staticClass:"qa-list"},_vm._l((_vm.qaList),function(item,key){return _c('li',{key:key,staticClass:"qa-item"},[_c('div',{ref:"title",refInFor:true,staticClass:"qa-item-title-box",on:{"click":function($event){return _vm.showHandler(key)},"mouseup":function($event){return _vm.clickUD(false)},"mousedown":function($event){return _vm.clickUD(true)}}},[_c('h5',{staticClass:"qa-item-title font-bold common-content"},[_vm._m(1,true),_c('div',{staticClass:"qa-item-icon",style:({ opacity: _vm.show[key] === 0 ? 1 : 0 })},[_c('i',{staticClass:"common-icon-plus"})]),_vm._v(" "+_vm._s(item.question)+" ")])]),_c('div',{staticClass:"qa-item-context",style:({ height: _vm.show[key] + 'px' })},[_c('div',{staticClass:"qa-item-info-box"},[_c('div',{staticClass:"qa-item-info common-content"},_vm._l((item.answer),function(child,index){return _c('div',{key:index,staticClass:"qa-item-info-text"},[_vm._v(" "+_vm._s(child)+" ")])}),0)])])])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.cursorState),expression:"cursorState"}],staticClass:"pointer pointer-bg",style:({ transform: `translate(${_vm.cursor.x}px, ${_vm.cursor.y}px)` })},[_c('img',{attrs:{"src":require("@/assets/images/variable/var_04_bg.png"),"alt":""}})]),_c('div',{staticClass:"pointer pointer-tip-box",style:({
      transform: `translate(${_vm.cursor.x}px, ${_vm.cursor.y}px)`
    })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.cursorState),expression:"cursorState"}],staticClass:"pointer-tip",style:({
        transform: `scale(${_vm.cursorClickState ? 0.9 : 1})`
      })})]),_c('div',{staticClass:"pointer pointer-tip-box",style:({
      transform: `translate(${_vm.cursor.x}px, ${_vm.cursor.y}px)`
    })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.cursorState),expression:"cursorState"}],staticClass:"pointer-corrugated",class:{ active: _vm.cursorClickState }})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.cursorState),expression:"cursorState"}],staticClass:"pointer pointer-cursor",style:({ transform: `translate(${_vm.cursor.x}px, ${_vm.cursor.y}px)` })},[_c('img',{attrs:{"src":require("@/assets/images/variable/var_04_cursor.png"),"alt":""}})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qa-title-box"},[_c('h2',{staticClass:"qa-title common-content"},[_vm._v("Q&A")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qa-item-icon"},[_c('i',{staticClass:"common-icon-minus"})])
}]

export { render, staticRenderFns }