<template>
  <Default>
    <div class="variable">
      <Stock />
      <Decentralized />
      <Transaction />
      <QAndA />
      <CPopup ref="popModule">
        <Module />
      </CPopup>
      <CPopup ref="popDvantage">
        <Dvantage />
      </CPopup>
    </div>
  </Default>
</template>

<script>
import Default from '@/components/layout/Default'
import Stock from '@/components/variable/Stock'
import Decentralized from '@/components/variable/Decentralized'
import Transaction from '@/components/variable/Transaction'
import QAndA from '@/components/variable/QAndA'
import CPopup from '@/components/common/CPopup'
import Module from '@/components/variable/Module'
import { EventBus } from '@/tools/bus'
import Dvantage from '@/components/variable/Dvantage'

export default {
  name: 'Variable',
  components: {
    Dvantage,
    Module,
    CPopup,
    QAndA,
    Transaction,
    Decentralized,
    Stock,
    Default
  },
  mounted() {
    this.$store.commit('setBridgeResourceReady', true)
    EventBus.$on('pop-module', () => {
      this.$refs.popModule.show()
    })
    EventBus.$on('pop-dvantage', () => {
      this.$refs.popDvantage.show()
    })
  },
  beforeDestroy() {
    EventBus.$off('pop-module')
    EventBus.$off('pop-dvantage')
  }
}
</script>

<style scoped></style>
