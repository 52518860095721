<template>
  <div class="fxDex">
    <div class="fx-content common-content">
      <div class="fx-info">
        <div class="fx-info-bg">
          <img
            src="~@/assets/images/variable/var_02_base1_backlight.svg"
            alt=""
          />
        </div>
        <h2 class="fx-info-title">
          F(X) <span class="common-angle-variable">VARIABLE</span>
        </h2>
        <div class="fx-info-note common-text white">
          Built on the Function X Variable chain, f(x) Variable is a
          decentralized trading platform where users can trade stock-based
          perpetual contract around the clock using these bridged stablecoins.
          Everything is decentralized, even the listing of new assets for
          trading, which will gradually open to community via governance voting.
        </div>
        <div class="fx-info-btn">
          <CButton type="comicWhite" :height="42" @click="goOut">
            <div class="common-btn-content">
              Learn More
              <div class="common-btn-icon">
                <i class="common-icon-open"></i>
              </div>
            </div>
          </CButton>
        </div>
      </div>
      <div class="fx-base">
        <img src="~@/assets/images/variable/var_02_base1.png" alt="" />
      </div>
      <div class="fx-change">
        <div class="fx-change-base">
          <div class="fx-change-base-monetary">
            <div class="fx-change-base-monetary-img">
              <img src="~@/assets/images/variable/var_02_coin.png" alt="" />
            </div>
          </div>
          <div class="fx-change-base-img">
            <img src="~@/assets/images/variable/var_02_base2.png" alt="" />
          </div>
        </div>
        <div class="fx-change-line">
          <div class="fx-change-line-item-box">
            <div class="fx-change-line-item"></div>
          </div>
          <div class="fx-change-line-item-box">
            <div class="fx-change-line-item"></div>
          </div>
        </div>
        <div class="fx-change-base">
          <div class="fx-change-base-monetary">
            <div class="fx-change-base-monetary-img">
              <img src="~@/assets/images/variable/var_02_asset.png" alt="" />
            </div>
          </div>
          <div class="fx-change-base-img">
            <img src="~@/assets/images/variable/var_02_base3.png" alt="" />
          </div>
        </div>
        <div class="fx-change-wire-l">
          <img src="~@/assets/images/variable/var_02_line_left.png" alt="" />
        </div>
        <div class="fx-change-wire-r">
          <img src="~@/assets/images/variable/var_02_line_right.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CButton from '@/components/common/CButton'
import { EventBus } from '@/tools/bus'

export default {
  name: 'FxDex',
  components: { CButton },
  data() {
    return {}
  },
  methods: {
    goOut() {
      EventBus.$emit('pop-module')
    }
  }
}
</script>

<style scoped lang="scss">
.fxDex {
  width: 100%;
  background-image: linear-gradient(to bottom, #0d0c15 0%, #191825 100%);
  padding-bottom: 120px;
  .fx-content {
  }
  .fx-info {
    position: relative;
    width: 880px;
    margin-left: auto;
    margin-right: auto;
    min-height: 450px;
    text-align: center;
    background-image: linear-gradient(
      180deg,
      rgba(178, 191, 255, 0) 0%,
      rgba(178, 191, 255, 0.1) 100%
    );
    box-sizing: border-box;
    padding: 140px 38px 80px;
  }
  .fx-info-bg {
    position: absolute;
    width: 798px;
    bottom: -108px;
    animation: 2s light-flash linear infinite alternate;
  }
  .fx-info-title {
    color: #fff;
  }
  .fx-info-note {
    margin-top: 8px;
  }
  .fx-info-btn {
    margin-top: 48px;
  }
  .fx-base {
    margin-left: auto;
    margin-right: auto;
    width: 962px;
    margin-top: -10px;
    position: relative;
  }
  .fx-change {
    margin-left: auto;
    margin-right: auto;
    width: 705px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
  }
  .fx-change-base {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    &:nth-of-type(1) .fx-change-base-monetary-img {
      width: 81px;
      animation: 4s move-up-down ease-in-out infinite alternate;
    }
    &:nth-of-type(3) .fx-change-base-monetary-img {
      width: 87px;
      animation: 4s move-up-down ease-in-out infinite alternate;
    }
  }
  .fx-change-base-monetary {
    width: 144px;
    height: 144px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(
      180deg,
      rgba(178, 191, 255, 0) 0%,
      rgba(178, 191, 255, 0.1) 100%
    );
  }
  .fx-change-base-img {
    width: 252px;
  }
  .fx-change-line {
    margin-top: 72px;
  }
  .fx-change-line-item-box {
    width: 200px;
    height: 3px;
    overflow: hidden;
    &:nth-of-type(1) .fx-change-line-item {
      background-image: linear-gradient(
        270deg,
        rgba(127, 218, 255, 0) 0%,
        #7fdaff 100%
      );
      animation: 2s move-left-right linear infinite reverse;
    }
    &:nth-of-type(2) .fx-change-line-item {
      background-image: linear-gradient(
        270deg,
        #729aff 0%,
        rgba(114, 154, 255, 0) 100%
      );
      animation: 2s move-left-right linear infinite;
    }
    & + .fx-change-line-item-box {
      margin-top: 20px;
    }
  }
  .fx-change-line-item {
    width: 100%;
    height: 100%;
  }
  .fx-change-wire-l {
    position: absolute;
    top: 21px;
    left: -176px;
    width: 189px;
  }
  .fx-change-wire-r {
    position: absolute;
    top: 21px;
    width: 182px;
    right: -177px;
  }
}
@keyframes light-flash {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.8;
  }
}
@keyframes move-up-down {
  from {
    transform: translateY(-10px);
  }
  to {
    transform: translateY(10px);
  }
}
@keyframes move-left-right {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}
</style>
