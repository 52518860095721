<template>
  <div class="module">
    <h2 class="md-title">5 UNDERLYING MODULES</h2>
    <div class="md-note common-text white">
      f(x) Variable is composed of 5 underlying modules, which guarantees the investors a fully decentralized,
      permission-less, reliable and stable experience for trading on the platform.
    </div>
    <ul class="md-list common-content">
      <li class="md-item" v-for="item in list" :key="item.title">
        <div class="md-item-img">
          <img :src="require('@/assets/images/variable/' + item.img)" alt="" />
        </div>
        <h3 class="md-item-title">{{ item.title }}</h3>
        <div class="md-item-note common-text white" v-html="item.note"></div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Module',
  data() {
    return {
      list: [
        {
          img: 'price@2x.png',
          title: 'Price Feed',
          note:
            'f(x)Variable is integrated with <a href="https://bandprotocol.com" target="_blank" rel="noopener noreferrer">Band Protocol</a>’s decentralized price oracle for spot prices used in framework, so as to avoid unnecessary liquidations and to combat market manipulation.'
        },
        {
          img: 'governance@2x.png',
          title: 'Governance',
          note:
            'Through voting and governance, the community decides the listing or delisting of assets, as well as how the Funding module should work during the stock exchanges in the real world is closed.'
        },
        {
          img: 'order@2x.png',
          title: 'Order',
          note:
            'All orders are executed on chain, including modifying and canceling orders, at a throughput of 1,000 transactions per second. Every address can have at most 10,000 open orders.'
        },
        {
          img: 'funding@2x.png',
          title: 'Funding Rates',
          note:
            'This module prevents lasting divergence in the price of perpetual contract markets and spot price. The Funding Rates are recalculated 4 times a day, and each time traders will either pay or receive funding depending on open positions.'
        },
        {
          img: 'liquidation@2x.png',
          title: 'Liquidation',
          note:
            'Liquidation occurs automatically when the Mark Price hits the liquidation price of a position. A Liquidation Order will be remain open until it is completely executed.'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.module {
  width: 100%;
  color: #fff;
  padding-bottom: 148px;
  .md-title {
    text-align: center;
  }
  .md-note {
    width: 804px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 8px;
  }
  .md-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin-top: 20px;
  }
  .md-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 330px;
    margin-top: 76px;
    & + .md-item {
      margin-left: 72px;
    }
    &:nth-last-of-type(1) {
      margin-left: 88px;
    }
    &:nth-last-of-type(2) {
      margin-left: 0;
    }
  }
  .md-item-img {
    width: 128px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: -29px;
      left: -29px;
      right: -29px;
      bottom: -29px;
      z-index: -1;
      background-image: url('~@/assets/images/variable/var_icon_backlight.svg');
    }
  }
  .md-item-title {
    margin-top: 12px;
  }
  .md-item-note {
    text-align: center;
    margin-top: 8px;
    :deep(a) {
      color: var(--note-white);
      text-decoration: underline;
    }
  }
}
</style>
